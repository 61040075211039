import { Image, Row, Col, Typography } from 'antd';
import './AppHeader.css';
import logo from '../../assets/logo.png';

function AppHeader() {
    return (
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle">
              <Image src={logo} width={100} preview={false} />
              <Typography.Title level={3} className="header-title">GIS Upload Tool</Typography.Title>
            </Row>
          </Col>
          <Typography.Link 
                href='https://res.cloudinary.com/marketsphere/image/upload/v1621981348/GIS/Brochures/imageupload_tooltraining.pdf'
          >Help</Typography.Link>
        </Row>
    )
}

export default AppHeader;
