const REACT_APP_CLIENT_ID=process.env.REACT_APP_CLIENT_ID
const REACT_APP_OKTA_DOMAIN=process.env.REACT_APP_OKTA_DOMAIN
const REACT_APP_REDIRECT_URI=process.env.REACT_APP_REDIRECT_URI
const REACT_APP_OKTA_ISSUER_CODE=process.env.REACT_APP_OKTA_ISSUER_CODE

export const oktaConfig = {
    clientId: REACT_APP_CLIENT_ID,
    issuer: `https://${REACT_APP_OKTA_DOMAIN}/oauth2/${REACT_APP_OKTA_ISSUER_CODE}`,
    redirectUri: REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: true,
};