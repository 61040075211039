import { useState } from 'react';
import { CheckOutlined, CopyOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons';
import { Card, Empty, List, Image, Button, Typography, Popconfirm } from 'antd';
import './FilesList.css';

function PdfPreviewMask({ item }) {
    return (
        <div className="preview-pdf">
            <Button type="link" icon={<ExportOutlined />} href={item['secure_url']} target="_blank" />
        </div>
    );
}

function FilesListItem({ item, handleDelete }) {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = function() {
        navigator.clipboard.writeText(item['secure_url']);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
    }
    
    //https://res.cloudinary.com/marketsphere/image/upload/c_limit,w_200,h_200/v1668185418/GIS/Brochures/LogistiCenter%20at%20New%20Castle%20County_Jamison%20Corner%20Rd%20Middletown%20DE.pdf.jpg
    const thumbUrl = item['secure_url'].replace('/upload/', '/upload/c_limit,w_200,h_200/').replace(/\.[^.]*$/, '.jpg');
    const previewType = item['public_id'].endsWith('pdf') ? false : { src: item['secure_url'] };
    return (
        <List.Item className={`files-list-item${item.newUpload ? ' new-item' : ''}`}>
            <Typography.Text ellipsis className='filename'>{item['filename']}</Typography.Text>
            <Card>
                <Image src={thumbUrl} className="files-list-item-image" preview={previewType} />
                {!previewType && <PdfPreviewMask item={item} />}
            </Card>
            <div className='footer-with-actions'>
                <Typography.Text ellipsis className='creator'>By {item['tags'][0]} ({new Date(item['created_at']).toLocaleDateString('en-US')})</Typography.Text>
                <div className='actions'>
                    <Button type='text' icon={copySuccess ? <CheckOutlined /> : <CopyOutlined />} onClick={handleCopy} disabled={copySuccess} />
                    <Popconfirm title='Are you sure you want to delete this file?' placement='top' onConfirm={() => handleDelete(item)} okText='Yes' cancelText='No'>
                        <Button type='text' icon={<DeleteOutlined />} />
                    </Popconfirm>
                </div>
            </div>
        </List.Item>
    )
}

function FilesListLoadMore({ loadMore }) {
    return (
        <List.Item className="files-list-item">
            <Card>
                <Button type='link' onClick={loadMore}>Load More...</Button>
            </Card>
        </List.Item>
    )
}

function FilesListComponent({ data, loading, loadMore, handleDelete }) {
    const { resources, total_count } = data;
    return (
        <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 6 }}
            dataSource={resources.length < total_count ? resources.concat([{}]) : resources}
            loading={loading}
            renderItem={item => Object.keys(item).length > 0 ? <FilesListItem item={item} handleDelete={handleDelete} /> : <FilesListLoadMore loadMore={loadMore}/>}
        />
    )
}

function FilesList({ data, loading, loadMore, handleDelete }) {
    return (
        <Card bordered={false} className="files-list">
            {data === null && <Empty description="Please select a document type" />}
            {data && <FilesListComponent data={data} loading={loading} loadMore={loadMore} handleDelete={handleDelete} />}
        </Card>
    )
}

export default FilesList;