import { Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { oktaConfig } from "./config/okta";
import Main from "./Main";

const oktaAuth = new OktaAuth(oktaConfig);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace("/");
  };
  
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route path="/login/callback" component={LoginCallback} />
      <SecureRoute path="/" exact component={Main} />
    </Security>
  );

}

export default App;
