const DocTypes = [
    {
        "value": "--Select--",
        "label": ""
    },
    {
        "value": "Brochures",
        "label": "Brochures"
    },
    {
        "value": "Charts And Graphs",
        "label": "Charts And Graphs"
    },
    {
        "value": "General Images",
        "label": "General Images"
    },
    {
        "value": "Icons",
        "label": "Icons"
    },
    {
        "value": "Legends",
        "label": "Legends"
    },
    {
        "value": "Logos",
        "label": "Logos"
    },
    {
        "value": "Site Plans",
        "label": "Site Plans"
    },
    {
        "value": "Videos",
        "label": "Videos"
    }
]
const SubscriptionKey = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;
const APIBaseUrl = process.env.REACT_APP_API_BASE_URL;
const SearchUrl = `${APIBaseUrl}/list?doc_type={docType}&search_term={searchTerm}&max_results=50&next_cursor={nextCursor}`;
const SignatureUrl = `${APIBaseUrl}/signature`
const UploadUrl = 'https://api.cloudinary.com/v1_1/marketsphere/image/upload';
const DeleteUrl = `${APIBaseUrl}/resources`;

export  { DocTypes, SearchUrl, SignatureUrl, UploadUrl, DeleteUrl, SubscriptionKey };