import { Layout } from 'antd';
import AppHeader from './components/app-header';
import FileManager from './components/file-manager/FileManager';
import './Main.css';

const { Header, Content } = Layout;

function Main() {
  return (
    <Layout className='main-layout'>
      <Header>
        <AppHeader />
      </Header>
      <Content className='main-content'>
        <FileManager />
      </Content>
    </Layout>
  );
}

export default Main;
