import { useState, useEffect } from 'react';
import { Card, Row, Col, Select, Input, Space, Button, Upload, Typography } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useOktaAuth } from '@okta/okta-react';
import FilesList from '../files-list';
import { DocTypes, SearchUrl, SignatureUrl, UploadUrl, DeleteUrl, SubscriptionKey } from './FileManager.constant';
import './FileManager.css';


const { Search } = Input;

const defaultData = { resources: [] };

function FileManager() {
    const [uploading, setUploading] = useState(false);
    const [docType, setDocType] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const { authState, oktaAuth } = useOktaAuth();

    const getHeaders = function() {
        const headers = new Headers();
        headers.append('subscription-key', SubscriptionKey);
        headers.append('authorization', `${authState?.accessToken.tokenType} ${authState?.accessToken.accessToken}`);
        return headers;
    }
    

    const fetchList = function(docType, searchTerm, currentData = defaultData) {
        setLoading(true);
        const url = SearchUrl.replace('{docType}', docType)
                             .replace('{searchTerm}', searchTerm)
                             .replace('{nextCursor}', currentData?.next_cursor || "");

        fetch(url, { headers: getHeaders() }).then(response => response.json())
            .then((responseData) => {
                setData(Object.assign(responseData, { resources: [...currentData.resources, ...responseData.resources] }));
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          setUserInfo(null);
        } else {
          oktaAuth.getUser().then((info) => {
            setUserInfo(info);
          }).catch((err) => {
            console.error(err);
            setUserInfo(null);
          });
        }
      }, [authState, oktaAuth]);

    const uploadFile = function(file) {
        setUploading(true);
        const timestamp = Date.now();
        const username = userInfo?.name;
        if(!username) {
            alert("User not signed in");
            return;
        }
        const payload = encodeURIComponent(`public_id=GIS/${docType}/${file.name}&tags=${username}&timestamp=${timestamp}`);
        fetch(`${SignatureUrl}?payload=${payload}`, { headers: getHeaders() }).then(response => response.json())
            .then(responseData => {
                const body = new FormData();
                body.append('file', file, file.name);
                body.append('timestamp', timestamp);
                body.append('public_id', `GIS/${docType}/${file.name}`);
                body.append('tags', username);
                body.append('api_key', responseData['api_key']);
                body.append('signature', responseData['signature']);
                fetch(UploadUrl, { method: 'POST', body })
                    .then(response => response.json())
                    .then((responseData) => {
                        setLoading(true);
                        data.resources.unshift({...responseData, newUpload: true, filename: file.name });
                        setLoading(false);
                        setUploading(false);
                    })
                    .catch((error) => {
                        console.error(error);
                        setUploading(false);
                    });
            });
    }

    const deleteFile = function(item) {
        setLoading(true);
        fetch(`${DeleteUrl}?public_ids=${item['public_id']}`, { method: 'DELETE', headers: getHeaders() })
            .then(response => response.json())
            .then((responseData) => {
                const itemIdx = data.resources.indexOf(item);
                data.resources.splice(itemIdx, 1);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    const handleSelect = function(value) {
        setDocType(value);
        setData(defaultData);
        if(value !== "") {
            fetchList(value, searchTerm);
        }
    }

    const handleSearch = function(value) {
        setSearchTerm(value);
        setData(defaultData);
        fetchList(docType, value);
    }

    const handleLoadMore = function() {
        fetchList(docType, searchTerm, data);
    }

    const handleFileUpload = function(props) {
        uploadFile(props.file);
    }

    const handleDelete = function(item) {
        deleteFile(item);
    }

    return (
        <div className='file-manager'>
            <Card bordered={false}>
                <Row align="middle" justify="space-between">
                    <Col>
                        <Space size="large">
                            <Col>
                                <Select className='doc-type-selector' options={DocTypes} onChange={handleSelect}></Select>
                            </Col>
                            <Col>
                                <Search className='search-box' placeholder="Search (case-sensitive)" allowClear onSearch={handleSearch} />
                            </Col>
                            <Col>
                            <Upload customRequest={handleFileUpload} showUploadList={false}>
                                <Button type='primary' icon={uploading ? <LoadingOutlined /> : <UploadOutlined />} disabled={uploading || !docType}>
                                    { uploading ? 'Uploading' : 'Click to Upload' }
                                </Button>
                            </Upload>
                            </Col>
                        </Space>
                    </Col>
                    <Col>
                        { docType && !loading && <Typography.Paragraph>Showing {data.resources.length} of {data.total_count}</Typography.Paragraph> }
                    </Col>                    
                </Row>
            </Card>
            <FilesList data={data} docType={docType} loading={loading} loadMore={handleLoadMore} handleDelete={handleDelete} />
        </div>
    );
}

export default FileManager;